<template>
  <div>
    <h2>Fast Break Stats</h2>
    <h3>Basketball Game Statistics 🏀</h3>
    <span v-if="loading">Loading...</span>
    <div class="scores" v-for="item in $store.state.games" :key="item.GameStatsID">
      <span class="team" @click="ShowPlayer(item.visiting_team)">{{ item.visiting_team }}: 60</span>
      <span>vs</span>
      <span class="team" @click="ShowPlayer(item.home_team)">{{ item.home_team }}: 72</span>
    </div>
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>Player</th>
            <th>Minutes</th>
            <th>Field Goals Made</th>
            <th>Field Goals Attempted</th>
            <th>Three Points Made</th>
            <th>Three Points Attempted</th>
            <th>Free Throws Made</th>
            <th>Free Throws Attempted</th>
            <th>Defensive Rebounds</th>
            <th>Offensive Rebounds</th>
            <th>Blocks</th>
            <th>Assists</th>
            <th>Steals</th>
            <th>Turnovers</th>
            <th>Personal Fouls</th>
            <th>Plus/Minus</th>
            <th>Usage Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $store.state.game_stats" :key="item.GameStatsID">
            <td>{{ item.player_name }}</td>
            <td>{{ item.Minutes }}</td>
            <td>{{ item.FieldGoalsMade }}</td>
            <td>{{ item.FieldGoalsAttempted }}</td>
            <td>{{ item.ThreePointsMade }}</td>
            <td>{{ item.ThreePointsAttempted }}</td>
            <td>{{ item.FreeThrowsMade }}</td>
            <td>{{ item.FreeThrowsAttempted }}</td>
            <td>{{ item.DefensiveRebounds }}</td>
            <td>{{ item.OffensiveRebounds }}</td>
            <td>{{ item.Blocks }}</td>
            <td>{{ item.Assists }}</td>
            <td>{{ item.Steals }}</td>
            <td>{{ item.Turnovers }}</td>
            <td>{{ item.PersonalFouls }}</td>
            <td>{{ item.PlusMinus }}</td>
            <td>{{ item.UsageRate }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="ball">
      🏀
    </div>
    <div class="version">
      <span>Prototype version</span>
    </div>
    <OurPlayer :isVisible="show_modal" @close="close">
      <div class="form">
        <span class="title">Players of {{ team_name }}</span>
        <div class="content">
          <span v-if="loading">Loading...</span>
          <div v-for="(item, index) in $store.state.players" :key="index">
            <span>{{ (index + 1) }}. {{ item.Name }} - {{ item.Height }}</span>
          </div>
        </div>
      </div>
    </OurPlayer>
  </div>
</template>

<script>
import OurPlayer from '@/components/OurPlayer.vue';

export default {
  data() {
    return {
      loading: false,
      show_modal: false,
      team_name: ''
    };
  },
  components: {
    OurPlayer
  },
  methods: {
    getGame_stats(url = null) {
      if (!url) {
        url = this.$store.state.baseUrl + '/game-stats/';
      }
      this.loading = true
      this.axios.get(url)
        .then(response => {
          this.$store.state.game_stats = response.data.results
          this.loading = false
        })
        .catch(error => {
          console.log(error);
          this.loading = false
        });
    },
    getGames(url = null) {
      if (!url) {
        url = this.$store.state.baseUrl + '/games/';
      }
      this.loading = true
      this.axios.get(url)
        .then(response => {
          this.$store.state.games = response.data.results
          this.loading = false
        })
        .catch(error => {
          console.log(error);
          this.loading = false
        });
    },
    ShowPlayer(keysearch) {
      this.show_modal = true
      this.team_name = keysearch
      this.loading = true
      this.axios.get(this.$store.state.baseUrl + `/players/?search=${keysearch}`)
        .then(response => {
          this.$store.state.players = response.data.results
          this.loading = false
        })
        .catch(error => {
          console.log(error);
          this.loading = false
        });

    },
    close() {
      this.show_modal = false
    },
  },
  mounted() {
    this.getGames();
    this.getGame_stats();
  },
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
  /* Enables horizontal scrolling */
}

table {
  width: 100%;
  /* Full width */
  border-collapse: collapse;
  /* Removes space between cells */
  border-radius: 8px;

}

th,
td {
  border: 1px solid #ddd;
  /* Cell border */
  padding: 8px;
  /* Cell padding */
  text-align: center;
  /* Center text */
}

th {
  background-color: #f2f2f2;
  /* Header background color */
}

.scores {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.team {
  cursor: pointer;
}
.ball{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  font-size: 30px;
}
.version{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 30px 0; 
}
</style>